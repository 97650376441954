import { useEffect } from 'react';

const useNetworkSpeedCheck = () => {
  useEffect(() => {
    const checkConnection = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        const { downlink } = connection;
        if (downlink < 0.5 && downlink > 0) {
          alert('Slow internet connection, please wait.');
        }
      }
    };

    const checkOfflineStatus = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        const { downlink } = connection;
        if (downlink <= 0){
        	alert('You are offline. Please check your internet connection.')
        }
      }
    };
    const intervalSlowInternet = setInterval(() => {
      checkConnection();
    }, 2000);

    const intervalCheckOfflineStatus = setInterval(() => {
      checkOfflineStatus();
    }, 1000);

    return () => {
      clearInterval(intervalSlowInternet);
      clearInterval(intervalCheckOfflineStatus);
    };
    
    const handleConnectionChange = () => {
    	checkConnection();
    };
    
    if (navigator.connection) {
      navigator.connection.addEventListener('change', handleConnectionChange);
    }

   	
    return () => {
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', handleConnectionChange);
      }
    };
/*    const interval = setInterval(() => {

      if (!navigator.onLine) {
        alert('You are offline. Please check your internet connection.');
      }

    }, 1000);

    return () => clearInterval(interval);*/
  }, []);
};

export default useNetworkSpeedCheck;