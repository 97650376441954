// highlight
import './utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

// lightbox
import 'react-image-lightbox/style.css'

import 'react-quill/dist/quill.snow.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import '_style.css'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {SettingsProvider} from 'utils/context/settings'
import {CollapseDrawerProvider} from 'utils/context/drawer'

import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import React from 'react'
/*
Bugsnag.start({
  apiKey: 'd543c4256472e5b07abb24b6bb97cf69',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)*/


  const USERSNAP_GLOBAL_API_KEY =  "70ccdab5-9822-416e-b752-ef1d60667f77";
  {/*api.show('${USERSNAP_API_KEY}') */}
ReactDOM.render(
  <React.StrictMode>
    {/*<ErrorBoundary>*/}
      <HelmetProvider>
          <Helmet>
          <script type="text/javascript" key="tawk.to">
          {`
          var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
          (function() {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/666a5148981b6c56477c93f6/1i07kr15t';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
            Tawk_API.onLoad = function() {
              Tawk_API.customStyle = {
                zIndex: 9999999,
                position: 'fixed',
                bottom: '50px !important',
                right: '20px',
                height: 'auto',
                width: 'auto'
              };
            };
          })();
          `}

          </script>
          {/*<script type="text/javascript">
            {`
              window.onUsersnapCXLoad = function(api) {
                api.init();

                api.on('open', function(event) {
                  // Set labels
                  event.api.setValue('labels', ['timekeeping'])
                })
                
                window.Usersnap = api;
                function setNotification(event) {
                  console.log(event)
                  fetch(
                    'https://discord.com/api/webhooks/1120527517837705216/S6nrAUBAInX4-NCJ1NFef0MywDawkqbPOKFRs7rOhlOlrptBdiSiQNHBRzYKBKT2sZzK',
                    {
                      method: 'post',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        "username": "Feedback",
                        "avatar_url": "https://img.freepik.com/free-vector/alarm-clock-concept-illustration_114360-12926.jpg",
                        "content": "on Timekeeping",
                        "embeds": [
                          {
                          "author": {
                              "name": "Click here to manage",
                              "url": " https://board.usersnap.com/IhOXRc0HT8A6TUh-",
                              "icon_url": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHNJRKm8T-3kbUlFAwj1eUhAwBSZsgUU720xZGVg8htGwD1BegnNqAnMRPE2LoIdsUVf0&usqp=CAU"
                              },
                              "title": "URL",
                              "url": event.values.client.url,
                              "description": event.values.client.url,
                            "color": 15258703,
                            "fields": [
                              {
                                "name": "Name / FB :",
                                "value": event.values.ordered_inputs[0].value,
                                "inline": true
                              },
                              {
                                "name": "Phone :",
                                "value": event.values.ordered_inputs[1].value,
                                "inline": true
                              },
                              {
                                "name": "Feedback :",
                                "value": event.values.comment.text
                              }
                            ],
                            "footer": {
                              "text": "Timekeeping",
                              "icon_url": "https://img.freepik.com/free-vector/alarm-clock-concept-illustration_114360-12926.jpg"
                            }
                          }
                        ]
                      }),
                    }
                  );
                }
                api.on('submit', setNotification);
              }
              var script = document.createElement('script');
              script.defer = 1;
              script.src = 'https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad';
              document.getElementsByTagName('head')[0].appendChild(script);
            `}
          </script>*/}
        </Helmet>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    {/*</ErrorBoundary>*/}
  </React.StrictMode>,
  document.getElementById('root'),
)

/*Bugsnag.notify(new Error('Test error'))*/

// If you want to enable client cache, register instead.
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
