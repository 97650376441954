import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Link, Stack, Container, Typography, Button, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LocationNeededDialog from '../components/locationDialog';

import Page from '../components/Page';
import storage from 'utils/storage';
import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: "aes" });

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '45vh',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: theme.spacing(0, 0),
}));

export default function TrackTime() {
  const navigation = useNavigate();
  const [user, setUser] = useState([]);
  const [appVersion, setAppVersion] = useState(process.env.REACT_APP_VERSION);

  const geolocation = async () => {
    await navigator.geolocation.watchPosition(function (position) {
      ls.set("latitude", position.coords.latitude);
      ls.set("longitude", position.coords.longitude);
    });
  };

  useEffect(() => {
    const load = async () => {
      const storedVersion = ls.get('appVersion');
      // Check if running in webview
      const isWebView = navigator.userAgent.includes('wv') || // Android WebView
                       navigator.userAgent.includes('Mobile/') && !navigator.userAgent.includes('Safari'); // iOS WebView
      
      if (!isWebView && (storedVersion === undefined || storedVersion === null || storedVersion === "" || storedVersion !== appVersion)) {
        alert("New update available! Please open the browser (Chrome or Safari), refresh the page, and re-add the timekeeping app to your home screen for the latest features and improvements. Thank you!");
      }
      ls.set("appVersion", appVersion);
      
      await geolocation();
      const local_user = await storage.getUser();
      if (!local_user) return false;
      const user = JSON.parse(local_user);
      setUser(user);
    };
    load();
  }, []);

  return (
    <RootStyle title="Sparkle Timekeeping | The New Face of Better Labor Efficiency">
      <Container maxWidth="sm">
        <ContentStyle>
          {/* Hero Section */}
          <Stack sx={{ mb: 5, textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              Time Tracking Made Easy
            </Typography>
            <Typography variant="body1">
              Make timekeeping more efficient for your operations needs.
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
              <Button variant="contained" color="primary" onClick={
                //  go to https://docs.google.com/document/d/1P7V38LsyHa5PdG52xnk25uMou9aITA9ZD0bN_Q_-RRM/edit?tab=t.0
                () => window.open('https://docs.google.com/document/d/1P7V38LsyHa5PdG52xnk25uMou9aITA9ZD0bN_Q_-RRM/edit?tab=t.0', '_blank')
              } >Learn More</Button>
              <Button variant="outlined" color="primary" 
                onClick={() => navigation('/login')} 
              >Request Demo</Button>
            </Stack>
          </Stack>

          {/* About Us Section */}
          <Divider sx={{ mb: 4 }} />
          <Stack spacing={3}>
            <Typography variant="h4" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2">
              Sparkle Timekeeping helps companies streamline their timekeeping process by allowing employees to clock in and out easily. We focus on providing accurate and reliable timekeeping that ensures labor efficiency and reduces manual entry errors.
            </Typography>
            <Typography variant="body2">
              Our solution ensures flexibility, giving both managers and employees control over time logs, with reviews and insights to optimize scheduling and time allocation.
            </Typography>
            <Typography variant="h5">
              Mission and Vision
            </Typography>
            <Typography variant="body2">
              <strong>Vision:</strong> To be the leading timekeeping solution for improving operational efficiency in Southeast Asia.
            </Typography>
            <Typography variant="body2">
              <strong>Mission:</strong> To provide companies and employees with the freedom to connect, track, and manage time effectively, increasing productivity and simplifying timekeeping processes.
            </Typography>
          </Stack>

          {/* Popular Features Section */}
          <Divider sx={{ my: 4 }} />
          <Stack spacing={3}>
            <Typography variant="h4" gutterBottom>
              Popular Features
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="space-around">
              <div>
                <img src="https://images.unsplash.com/photo-1456574808786-d2ba7a6aa654?q=80&w=1944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                 alt="Accurate Time Tracking" width="100" />
                <Typography variant="body1" align="center">Accurate Time Tracking</Typography>
              </div>
              <div>
                <img src="https://images.unsplash.com/photo-1671109709660-fb906714d82e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Shift Scheduling" width="100" />
                <Typography variant="body1" align="center">Shift Scheduling</Typography>
              </div>
              <div>
                <img src="https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                 alt="Real-time Reports" width="100" />
                <Typography variant="body1" align="center">Real-time Reports</Typography>
              </div>
              <div>
                <img src="https://images.unsplash.com/photo-1576267423429-569309b31e84?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
                alt="Employee Management" width="100" />
                <Typography variant="body1" align="center">Employee Management</Typography>
              </div>
            </Stack>
          </Stack>

          {/* Contact Us Section */}
          <Divider sx={{ my: 4 }} />
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography variant="h6">Contacts</Typography>
              <Typography variant="body2">For client inquiries, sample demos, and cost estimates, use the chat widget on the bottom -right or reach us at:</Typography>
              <Typography variant="body2">827 P. Paredes, Sampaloc, Manila, 1008 Metro Manila</Typography>
              <Typography variant="body2">Monday - Friday 8am-5pm PST</Typography>
              <Typography variant="body2">Phone: 0995-042-6755</Typography>
              <Typography variant="body2">Email: d.ilagan@sparkles.com.ph</Typography>
            </Stack>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
